import { Component, ComponentChild, FunctionalComponent, h, RenderableProps } from 'preact';
import { SvgIcon } from './SvgIcon';
import { Translator } from '../sgwt-i18n';

interface ISidePanelProps {
  expanded?: boolean;
  widget: 'sgwt-help-center' | 'sgwt-account-center';
  onClose: (fromClickOut: boolean) => void;
}

interface ISidePanelHeaderProps {
  expanded?: boolean;
  expandable?: boolean;
  changeExpandableState?: () => void;
  hasPreviousButton: boolean;
  title: ComponentChild;
  onClose: () => void;
  onPrevious?: () => void;
  translator: Translator;
}

export class SidePanel extends Component<RenderableProps<ISidePanelProps>> {
  private element?: HTMLDivElement;

  componentDidMount() {
    setTimeout(() => {
      // Use of setTimeout to prevent this action to be checked when the user clicks on
      // one of the User Toolbar icons...
      document.addEventListener('click', this.handleOutsideClick, false);
      document.addEventListener('touchend', this.handleOutsideClick, false);
    });
    document.addEventListener('keydown', this.handleKeydown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('touchend', this.handleOutsideClick, false);
    document.removeEventListener('keydown', this.handleKeydown, false);
  }

  /**
   * We check if the click has been made outside the Side panel, so we close it.
   */
  private handleOutsideClick = (event: Event) => {
    const node = event.target as Node;
    if (this.element && !this.element.contains(node)) {
      // In some cases, the HTML node that receives the click has been (or is being) unmounted.
      // The root panel does not contains this node anymore, so the statement `this.element.contains(node)`
      // returns `false`. It does not mean that the click is outside the panel. So we check the X position of
      // cursor during the click, and check if it is in the panel (since the panel takes the whole height, we
      // only need to check the X position, not Y).
      const clickX = (event as any).clientX;
      const elementX = this.element.getBoundingClientRect().left;
      if (!!clickX && !!elementX && clickX < elementX) {
        // The click has been made outside the Side Panel, so we close it...
        this.props.onClose(true);
      }
    }
  };

  private handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.which === 27) {
      this.props.onClose(false);
    }
  };

  render() {
    return (
      <div
        ref={(elt: HTMLDivElement) => { this.element = elt; }}
        class={`card shadow-max ${this.props.widget}-panel ${this.props.expanded ? 'expanded' : ''}`}
        style={{
          position: 'fixed',
          'z-index': 1035, // greater than most elements (navbar included), but lower than modal dropback (1040)
          height: '100vh',
          right: 0,
          top: 0
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export const SidePanelHeader: FunctionalComponent<RenderableProps<ISidePanelHeaderProps>> = (props: RenderableProps<ISidePanelHeaderProps>) => (
  <div class="card-header px-4 pt-4 pb-3">
    <div class="d-flex justify-content-between align-items-center">
      {
        props.hasPreviousButton &&
          <button
            type="button"
            role="navigation"
            aria-label={props.translator.translate('message.back')}
            class="action-link back-action mr-2 me-2 btn btn-link px-0"
            onClick={props.onPrevious}
          >
            <i aria-hidden="true" class="sgwt-widgets-icon text-secondary">
              <SvgIcon type="arrow_back" />
            </i>
          </button>
      }
      {
        props.hasPreviousButton ?
          <h2 class="h5 m-0">{props.title}</h2>
          :
          <h2 class="h4 m-0">{props.title}</h2>
      }
      <span>
        {
          props.expandable &&
            <button
              type="button"
              class="btn btn-link sgwt-panel-expand-button px-0 mr-3 me-3 d-none d-md-inline-block"
              onClick={() => { props.changeExpandableState && props.changeExpandableState(); }}
              aria-label={props.translator.translate(`panel.${props.expanded ? 'shrink' : 'expand'}`)}
            >
              <i aria-hidden="true" class="sgwt-widgets-icon">
                <SvgIcon width={24} height={24} type={props.expanded ? 'fullscreen_exit' : 'fullscreen' } />
              </i>
            </button>
        }
        <button
          type="button"
          aria-label={props.translator.translate('message.close')}
          class="btn btn-link px-0"
          onClick={props.onClose}
        >
          <i aria-hidden="true" class="sgwt-widgets-icon">
            <SvgIcon width={24} height={24} type="close" />
          </i>
        </button>
      </span>
    </div>
    {props.children}
  </div>
);
