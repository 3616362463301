import { h, FunctionalComponent, RenderableProps } from 'preact';
import { SvgIcon } from './SvgIcon';

export interface IBasicModalHeaderProps {
  class?: string;
  title?: string;
  onClose?: () => void;
}

export interface IBasicModalBodyProps {
  class?: string;
}

export interface IBasicModalFooterProps {
  class?: string;
}

export interface IBasicModalProps {
  class?: string;
  size?: 'sm' | 'md' | 'lg';
  onClose: () => void;
}

export const BasicModalHeader: FunctionalComponent<RenderableProps<IBasicModalHeaderProps>> =
  (props: RenderableProps<IBasicModalHeaderProps>) => {
    if (props.title) {
      return (
        <div class={`modal-header position-relative ${props.class}`}>
          <h3 class="modal-title">{props.title}</h3>
          <button type="button" class="btn btn-link sgwt-account-center-close position-absolute flex-center" data-dismiss="modal" aria-label="Close" onClick={props.onClose}>
            <SvgIcon width={24} height={24} type="close" />
          </button>
        </div>
      );
    }
    return (
      <div class={`modal-header ${props.class}`}>
        {props.children}
      </div>
    );
  };

export const BasicModalBody: FunctionalComponent<RenderableProps<IBasicModalBodyProps>> =
  (props: RenderableProps<IBasicModalBodyProps>) => {
    return (
      <div class={`modal-body ${props.class}`}>
        {props.children}
      </div>
    );
  };

export const BasicModalFooter: FunctionalComponent<RenderableProps<IBasicModalFooterProps>> =
  (props: RenderableProps<IBasicModalFooterProps>) => {
    return (
      <div class={`modal-footer ${props.class}`}>
        {props.children}
      </div>
    );
  };

export const BasicModal: FunctionalComponent<RenderableProps<IBasicModalProps>> =
  (props: RenderableProps<IBasicModalProps>) => {
    let modalContent: HTMLLIElement;

    /**
     * Check if the click is made outside the modal content, so we can close it.
     */
    const handleClick = (event: Event) => {
      if (modalContent && !modalContent.contains(event.target as Node)) {
        props.onClose();
      }
    };

    return (
      <div>
        <div
          class="modal fade show d-block"
          id="basicModal"
          role="dialog"
          onClick={(event: Event) => { handleClick(event); }}
          aria-hidden="true">

          <div class={`modal-dialog ${props.size ? ('modal-' + props.size) : ''}`} role="document">
            <div class={`modal-content shadow-max ${props.class}`} ref={(elt: HTMLLIElement) => {
              modalContent = elt;
            }}>
              {props.children}
            </div>
          </div>
        </div>

        <div class="modal-backdrop fade show"/>
      </div>
    );
  };
