import { Component, h } from 'preact';
import { SvgIcon } from './SvgIcon';

interface ISearchFieldState {
  value: string;
  hasError: boolean;
}

interface ISearchFieldProps {
  onSearch: (value: string) => void;
  placeholder: string;
  searchValue?: string;
}

export class SearchField extends Component<ISearchFieldProps, ISearchFieldState> {
  private timeoutId: any = null;

  constructor(props: ISearchFieldProps) {
    super(props);
    this.state = {
      value: props.searchValue || '',
      hasError: false
    };
  }

  private searchValueChanged = (e: any): void => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    if (e.target.value !== this.state.value) {
      return this.setState({ value: e.target.value }, () => {
        this.timeoutId = setTimeout(() => {
          this.search();
        }, 350);
      });
    }
  };

  private handleKeyUp = (e: any): void => {
    if (e.keyCode === 27) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
      return this.setState({ value: '' }, () => {
        this.timeoutId = setTimeout(() => {
          this.search();
        }, 350);
      });
    }
  };

  private search = (e?: any): void => {
    const { value, hasError } = this.state;
    // const { next } = this.props;
    if (e) {
      e.preventDefault();
    }
    if (e && value === '') {
      this.setState({ hasError: true });
    } else {
      if (hasError) {
        this.setState({ hasError: false });
      }
      this.props.onSearch(value);
    }
  };

  render() {
    return (
      <form class="form-inline flex-grow-1" onSubmit={this.search} autocomplete="off">
        <label for="inputSearch" class="input-group input-group-lg flex-grow-1">
          <div class="input-group-prepend input-group-merged input-icon-start">
            <i class="sgwt-widgets-icon icon-sm text-secondary">
              <SvgIcon type="search" />
            </i>
          </div>
          <input
            type="search"
            value={this.state.value}
            onInput={this.searchValueChanged}
            onKeyUp={this.handleKeyUp}
            class={`form-control ${this.state.value ? '' : 'empty-search'}`}
            id="inputSearch"
            aria-label={this.props.placeholder}
            placeholder={this.props.placeholder}
            autofocus={true}
            ref={input => { input && setTimeout(() => input.focus()); }}
          />
        </label>
      </form>
    );
  }
}
