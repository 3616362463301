import * as moment from 'moment';

declare module 'moment' {
  interface Moment {
    // upgrade Moment with the fromString method
    fromString(param?: string | null, format?: string | string[]): moment.Moment | null;
  }
}
/** Override moment method for not show the depreciation warning in the console */
(moment as any).createFromInputFallback = function(config: any) {
  // unreliable string magic, or
  config._d = new Date(config._i);
};

// source : https://basarat.gitbooks.io/typescript/content/docs/types/literal-types.html
/** Utility function to create a K:V from a list of strings */
function strEnum<T extends string>(o: Array<T>): { [K in T]: K } {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

/** Create a K:V */
export const DATETIMEPICKER_TYPES = strEnum(['all', 'date', 'time']);

/** Create a Type */
export type DATETIMEPICKER_TYPES = keyof typeof DATETIMEPICKER_TYPES;

export const DATETIMEPICKER_THEMES = strEnum([
  'arrow',
  'danger',
  'dark',
  'default',
  'info',
  'link',
  'primary',
  'primary-alt',
  'reverse',
  'simple',
  'success',
  'warning',
  'sgbs'
]);

export type DATETIMEPICKER_THEMES = keyof typeof DATETIMEPICKER_THEMES;
