import { props } from 'skatejs';
import * as moment from 'moment';

/**
 * Starting with Chrome 109, the non-standard `event.path` is removed.
 * `event.composedPath()` should be used instead.
 * https://chromestatus.com/feature/5726124632965120
 * The `event.path` is used by the <sgwt-datetime-picker> through these dependencies:
 *   @sgwt-widget-hack/react-datetime 2.10.1-beta.1
 *     +-> @sgwt-widget-hack/react-onclickoutside 6.5.0-beta.1
 * Since these dependencies are too complex to evolve, we put a polyfill for `event.path`
 * that simply calls `event.composedPath()`.
 */
if (!Event.prototype.hasOwnProperty('path')) {
  console.debug('`event.path` is removed with Chrome 109, we integrate a Polyfill for compatibility issue.');
  Object.defineProperty(Event.prototype, 'path', {
    get() { return this.composedPath(); }
  });
}

/** Lodash helper */
export const _camelCase = require('lodash.camelCase');
export const _kebabCase = require('lodash.kebabcase');

/** Hack SkateJS boolean props & any for special moment props */
export const booleanProps: any = {
  ...props.boolean,
  coerce: (v: any): boolean =>
    v
      ? typeof v === 'boolean'
        ? v
        : typeof v === 'string' && v === 'true' ? true : false
      : false,
  deserialize: (v: string): boolean =>
    v ? (v === 'true' ? true : false) : false,
  serialize: (v: boolean): string => (v ? 'true' : 'false')
};
export const momentProps: any = {
  ...props.any,
  coerce: (v: any): any => v,
  deserialize: (v: string): any =>
    moment.isMoment(v) ? v : moment().fromString(v)
};

/** Helper method for test if an object is empty */
export const isEmptyObject = (obj?: object | null): boolean => {
  return !obj || Object.keys(obj).length === 0;
};

/** Helper method for transform a string to a function - from https://stackoverflow.com/a/4351575 */
export function executeFunctionByName(functionName: string, context: any) {
  const namespaces = functionName.split('.');
  const func = namespaces.pop();
  for (let i = 0; i < namespaces.length; i++) {
    context = context[namespaces[i]];
  }
  return context[func!];
}

/** Helper method for match a string to build a Moment object */
moment.fn.fromString = function(
  this: moment.Moment,
  param?: string | null,
  format?: string | string[]
): moment.Moment | null {
  if (!param) {
    return null;
  }
  const value = param.toLowerCase();
  switch (value) {
    case 'today':
    case 'now':
      return this.clone();
    case 'tomorrow':
      return this.clone().add(1, 'days');
    case 'yesterday':
      return this.clone().subtract(1, 'days');
    case 'last working day': {
      let daysToSubstract = 1;
      const day = this.clone().day();
      if (day === 0) { // Sunday -[-2]-> Friday
        daysToSubstract = 2;
      } else if (day === 1) { // Monday -[-3]-> Friday
        daysToSubstract = 3;
      }
      return this.clone().subtract(daysToSubstract, 'days');
    }
    case 'next working day': {
      const day = this.clone().day();
      let daysToAdd = 1;
      if (day === 5) { // Friday -[+3]-> Monday
        daysToAdd = 3;
      } else if (day === 6) { // Satursday -[+2]-> Monday
        daysToAdd = 2;
      }
      return this.clone().add(daysToAdd, 'days');
    }
    case 'day before last working day': {
      let dayBeforelastWorkingDayDiff = 2;
      const day = this.clone().day();
      if (day === 0 || day === 1) {
        dayBeforelastWorkingDayDiff = day + 3;
      } else if (day === 2) {
        dayBeforelastWorkingDayDiff = 4;
      }
      return this.clone().subtract(dayBeforelastWorkingDayDiff, 'days');
    }
    default:
      try {
        const START_OF_LAST = 'start of last ';
        const END_OF_LAST = 'end of last ';
        const START_OF = 'start of ';
        const END_OF = 'end of ';
        const FORMULA = 'formula:';
        let unitOfTime: any;
        if (value.length > START_OF_LAST.length && value.substr(0, START_OF_LAST.length) === START_OF_LAST) {
          unitOfTime = value.substr(START_OF_LAST.length, value.length).trim();
          return this.clone().subtract(1, unitOfTime).startOf(unitOfTime);
        } else if (value.length > END_OF_LAST.length && value.substr(0, END_OF_LAST.length) === END_OF_LAST) {
          unitOfTime = value.substr(END_OF_LAST.length, value.length).trim();
          return this.clone().subtract(1, unitOfTime).endOf(unitOfTime);
        } else if (value.length > START_OF.length && value.substr(0, START_OF.length) === START_OF) {
          unitOfTime = value.substr(START_OF.length, value.length).trim();
          return this.clone().startOf(unitOfTime);
        } else if (value.length > END_OF.length && value.substr(0, END_OF.length) === END_OF) {
          unitOfTime = value.substr(END_OF.length, value.length).trim();
          return this.clone().endOf(unitOfTime);
        } else if (value.length > FORMULA.length && value.substr(0, FORMULA.length) === FORMULA) {
          const BASE_INT = 10;
          let momentDateTime = this.clone();
          const formulas = value.substr(FORMULA.length, value.length).trim().split(/;/g);
          formulas.forEach(formula => {
            if (formula.indexOf('(') && formula.charAt(formula.length - 1) === ')') {
              const action = formula.substr(0, formula.indexOf('('));
              const params: any[] = (formula.substr(formula.indexOf('(') + 1, (formula.length - 1) - (formula.indexOf('(') + 1))).split(/,/g);
              switch (action) {
                case 'add':
                  momentDateTime = momentDateTime.add(parseInt(params[0], BASE_INT), params[1]);
                  break;
                case 'subtract':
                  momentDateTime = momentDateTime.subtract(parseInt(params[0], BASE_INT), params[1]);
                  break;
                case 'startof':
                  momentDateTime = momentDateTime.startOf(params[0]);
                  break;
                case 'endof':
                  momentDateTime = momentDateTime.endOf(params[0]);
                  break;
                default:
                  console.error('[sgwt-datetime-picker]', `Error in action analysis: ${action}`, param);
              }
            }
          });
          return momentDateTime;
        } else {
          return format ? moment(param, format, true) : moment(param);
        }
      } catch (err) {
        console.error('[sgwt-datetime-picker]', `Error in parameter analysis: ${param}`, err);
        return this.clone();
      }
  }
};
