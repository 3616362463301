import {
  h,
  initializeWidgetConfiguration,
  SgWidget,
  WidgetConfigurationProvider,
  WithGlobalCss
} from '@sgwt-widget/core';
import { ComponentProps, emit, props } from 'skatejs';
import { SvgIcon } from '../common/components';
import * as css from './sgwt-datetime-picker.scss';
import {
  DATETIMEPICKER_THEMES,
  DATETIMEPICKER_TYPES
} from './sgwt-datetime-picker.types';
import {
  _camelCase,
  _kebabCase,
  booleanProps,
  executeFunctionByName,
  isEmptyObject,
  momentProps
} from './shared/utils';
import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/zh-cn';

import './sgwt-datetime-picker.scss';
import { SgwtWidgetName, startWidgetMonitoring } from '../common/monitoring';

moment.locale('en');
/** Import the react-datetime component */
const ReactDatetime = require('@sgwt-widget-hack/react-datetime');

const SGWT_DATETIME_PICKER_NAMESPACE = 'sgwtDatetimePicker';
const guid = () => {
   const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export interface ISgwtDatetimePickerProps {
  type?: DATETIMEPICKER_TYPES;
  theme?: DATETIMEPICKER_THEMES;
  className?: string;
  closeOnSelect?: boolean;
  closeOnTab?: boolean;
  dateFormat?: string;
  defaultValue?: any;
  disabled?: boolean;
  disableOnClickOutside?: boolean;
  displayDateFormat?: string;
  displayTimeFormat?: string;
  input?: boolean;
  inputProps?: object;
  isLoaded?: boolean;
  isValid?: boolean;
  isValidDate?: string;
  isValidDateJs?: string;
  keyName?: string;
  label?: string;
  locale?: string;
  open?: boolean;
  placeholder?: string;
  returnEmpty?: boolean;
  strictParsing?: boolean;
  timeConstraints?: object | undefined;
  timeFormat?: string;
  utc?: boolean;
  value?: any;
  viewMode?: string;
}

export class SgwtDatetimePicker extends SgWidget<ISgwtDatetimePickerProps> {
  public static is = 'sgwt-datetime-picker';

  public static props: ComponentProps<
    ISgwtDatetimePickerProps
  > = {
    type: { ...props.any, default: DATETIMEPICKER_TYPES.all },
    theme: { ...props.any, default: DATETIMEPICKER_THEMES.sgbs },
    className: props.string,
    closeOnSelect: { ...booleanProps, default: true },
    closeOnTab: { ...booleanProps, default: true },
    dateFormat: { ...props.string, default: 'YYYY-MM-DD' },
    defaultValue: momentProps,
    disabled: props.boolean,
    disableOnClickOutside: { ...booleanProps, default: false },
    displayDateFormat: { ...props.string, default: 'DD MMM YYYY' },
    displayTimeFormat: { ...props.string, default: 'hh:mm A' },
    input: { ...booleanProps, default: true },
    inputProps: props.object,
    isLoaded: { ...booleanProps, default: false },
    isValid: { ...booleanProps, default: true },
    isValidDate: props.string,
    isValidDateJs: props.string,
    keyName: {
      ...props.string,
      coerce: (v: any): any => _camelCase(v),
      default: 'value'
    },
    label: props.string,
    locale: { ...props.string, default: 'en' },
    open: booleanProps,
    placeholder: props.string,
    returnEmpty: booleanProps,
    strictParsing: { ...booleanProps, default: true },
    timeConstraints: { ...props.object, default: undefined },
    timeFormat: { ...props.string, default: 'HH:mm' },
    utc: booleanProps,
    value: momentProps,
    viewMode: { ...props.string, default: 'days' }
  };

  private _firstTime: boolean = true;
  private _refs: {
    container: HTMLDivElement | null;
    el: any;
  } = { container: null, el: null };
  private isLoaded: boolean = false;
  private isValid: boolean = true;
  private uid: string = '';
  private value: any = undefined;

  constructor() {
    super();
    this.widgetConfiguration.log(`${SgwtDatetimePicker.is} constructed`);
  }

  public connectedCallback() {
    super.connectedCallback();
  }

  public disconnectedCallback() {
    this._isValidDateJsDestroy();
    if (this._refs.container) {
      this._refs.container.removeEventListener('click', this.fixTimeSelectorClick);
    }
    super.disconnectedCallback();
  }

  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
    if (oldValue !== newValue) {
      super.attributeChangedCallback(name, oldValue, newValue);
      if (name.toLowerCase() === 'is-valid-date-js') {
        this._isValidDateJsDestroy();
        if (newValue) { this._isValidDateJsInit(); }
      }
      if (name.toLowerCase() === 'value') {
        // format the first date time value if setted
        if (this.value && this._firstTime) {
          this.value = moment().fromString(newValue, [this._getExpectedFormat(), this._getExpectedFormat(true)]);
        }
        // the setTimeout is used for all values update, but very necessary for the first time
        setTimeout(() => {
          this._emitEvent('datetime-updated', this.userSelection);
        });
      }
    }
  }

  public render({
    type,
    theme,
    isLoaded,
    isValid,
    isValidDateJs,
    label,
    disabled,
    value
  }: ISgwtDatetimePickerProps): JSX.Element {
    const props = this._getCurrentConfiguration();
    const disable = (isValidDateJs && !this.uid && !isLoaded) || disabled;
    const btnClass = theme === DATETIMEPICKER_THEMES.sgbs ? 'sgbs-btn-default' : `btn-${theme}`;
    return (
      <WidgetConfigurationProvider widgetConfiguration={this.widgetConfiguration}>
        <WithGlobalCss styles={css}>
          <div
            class={`form-group ${isValid ? '' : 'has-error'}`}
            ref={(container: HTMLDivElement) => {
              this._refs.container = container;
            }}
          >
            <div class="input-group">
              {
                label &&
                <span class="input-group-prepend">
                  <button class={`btn ${btnClass}`} disabled={disable} onClick={() => !disable && this.toggleCalendar()} type="button">
                    {label}
                  </button>
                </span>
              }
              {
                disable ?
                  <div class="rdt">
                    <input type="text" class="form-control date datepicker-holder" placeholder="" disabled={true} value={this._getFormattedValue(value, true)} />
                  </div>
                  :
                  <ReactDatetime
                    ref={(el: any) => {
                      this._refs.el = el;
                    }}
                    {...props}
                    onChange={this._onChange}
                  />
              }
              {
                !label &&
                  <span class="input-group-btn">
                    <button
                      class={`btn ${btnClass} btn-icon`}
                      disabled={disable}
                      onClick={() => !disable && this.toggleCalendar()}
                      type="button"
                    >
                      <i class="sgwt-widgets-icon icon-sm">
                        <SvgIcon type={type === DATETIMEPICKER_TYPES.time ? 'access_time' : 'date_range'} />
                      </i>
                    </button>
                  </span>
              }
            </div>
          </div>
        </WithGlobalCss>
      </WidgetConfigurationProvider>
    );
  }

  public rendered() {
    if (this._firstTime) {
      if (this._refs.container) { // Timepicker fix...
        this._refs.container.addEventListener('click', this.fixTimeSelectorClick);
      }
      this._firstTime = false;
      this._emitEvent('ready');
      startWidgetMonitoring(SgwtWidgetName.DATETIME_PICKER);
    }
  }

  /** Fix */
  private fixTimeSelectorClick = (event: MouseEvent) => {
    if (event && (event.target! as Element).classList.contains('rdtBtn')) {
      if (event) { event.preventDefault(); }
      this._refs.el.setState({ open: !this._refs.el.open });
    }
  };

  /** Helper for return the current user selection */
  public get userSelection(): ISgwtDatetimePickerProps {
    return !this.value && this.props.returnEmpty
      ? {}
      : { [this.props.keyName!]: this._getFormattedValue(this.value) };
  }

  /** Helper for set the configuration & current user selection */
  public set userSelection(selection: ISgwtDatetimePickerProps) {
    if (isEmptyObject(selection)) {
      // selection is an empty object
      this.attributeChangedCallback('value', this.props.value, null);
    } else {
      Object.keys(this.props).forEach((key: string) => {
        if (typeof (selection as any)[key] !== 'undefined') {
          this.attributeChangedCallback(
            _kebabCase(key),
            (this.props as any)[key],
            (selection as any)[key]
          );
        }
      });
      if (
        this.props.keyName! !== 'value' &&
        typeof (selection as any)[this.props.keyName!] !== 'undefined'
      ) {
        // KeyName is not 'value'
        this.attributeChangedCallback(
          'value',
          this.props.value,
          (selection as any)[this.props.keyName!]
        );
      }
    }
  }

  /** Helper method for show/hide the calendar picker */
  public toggleCalendar = (e?: MouseEvent | TouchEvent | KeyboardEvent): void => {
    if (e) {
      e.preventDefault();
    }
    this._refs.el.setState({ open: !this._refs.el.open });
  };

  private _destroyCallback = () => {
    /* Nothing to do */
  };

  private _emitEvent = (name: string, detail?: object) => {
    this.widgetConfiguration.log(
      `[skatejs][event] '${SgwtDatetimePicker.is}--${name}' - ${detail
        ? JSON.stringify(detail)
        : '{}'}`
    );
    emit(this, `${SgwtDatetimePicker.is}--${name}`, { detail: detail || {} });
  };

  /** Helper for get the current props conifuration */
  private _getCurrentConfiguration = () => {
    const PARSEINT_BASE: number = 10;
    const {
      type,
      className,
      closeOnSelect,
      closeOnTab,
      dateFormat,
      defaultValue,
      disableOnClickOutside,
      displayDateFormat,
      displayTimeFormat,
      input,
      inputProps,
      isValid,
      isValidDate,
      isValidDateJs,
      locale,
      open,
      placeholder,
      strictParsing,
      timeConstraints,
      timeFormat,
      utc,
      value,
      viewMode
    }: ISgwtDatetimePickerProps = this.props;
    return {
      className,
      closeOnSelect,
      closeOnTab,
      dateFormat:
        type === DATETIMEPICKER_TYPES.time
          ? false
          : dateFormat === 'true' ||
            (dateFormat === 'false' ? false : displayDateFormat),
      defaultValue,
      disableOnClickOutside,
      input,
      inputProps: {
        placeholder: placeholder || this._getExpectedFormat(true),
        ...inputProps,
        className: `form-control date datepicker-holder ${(inputProps as any).className ? (inputProps as any).className : ''}${!isValid ? ' is-invalid' : ''}`
      },
      isValidDate: isValidDateJs && this.uid && (window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid].isValidDate !== undefined
        ? executeFunctionByName('isValidDate', (window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid]).bind((window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid])
        : isValidDate ? executeFunctionByName(isValidDate, window) : null,
      locale,
      open,
      strictParsing,
      timeConstraints,
      timeFormat:
        type === DATETIMEPICKER_TYPES.date
          ? false
          : timeFormat === 'true' ||
            (timeFormat === 'false' ? false : displayTimeFormat),
      utc,
      value,
      viewMode:
        viewMode && /^\d+$/.test(viewMode)
          ? parseInt(viewMode, PARSEINT_BASE)
          : viewMode
    };
  };

  /** Helper for return the expected format */
  private _getExpectedFormat = (display?: boolean) => {
    const {
      type,
      dateFormat,
      displayDateFormat,
      displayTimeFormat,
      timeFormat
    } = this.props;
    return (type === DATETIMEPICKER_TYPES.time
      ? display ? displayTimeFormat : timeFormat
      : type === DATETIMEPICKER_TYPES.date
        ? display ? displayDateFormat : dateFormat
        : (display
            ? `${displayDateFormat} ${displayTimeFormat}`
            : `${dateFormat}T${timeFormat}:ss.SSSZ`).trim()) || '';
  };

  /** Helper for return the formatted value */
  private _getFormattedValue = (
    v: moment.Moment,
    display?: boolean
  ): string => {
    return v && moment.isMoment(v)
      ? v.format(this._getExpectedFormat(display))
      : '';
  };

  private _globalErrorHandle(event: ErrorEvent) {
    this.widgetConfiguration.log(event.message);
    event.preventDefault();
  }

  private _initCallback = (value?: string) => {
    this.isLoaded = true;
    if (value) {
      this.attributeChangedCallback(
        'value',
        this.props.value,
        value
      );
    }
  };

  private _isValidDateJsDestroy = () => {
    if (this.uid) {
      if ((window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid].destroy !== undefined) {
        (window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid].destroy(this._destroyCallback);
      }
      const script = document.querySelector(`script[id='${this.uid}']`) as HTMLScriptElement | null;
      if (script) {
        script.remove();
      }
    }
    this.isLoaded = false;
  };

  private _isValidDateJsInit = async () => {
    const { isValidDateJs } = this.props;
    if (isValidDateJs) {
      const textContent = await this.widgetConfiguration
        .fetch(isValidDateJs, {
          // headers: { accept: 'application/javascript'},
          method: 'GET'
        })
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error(response.statusText);
        })
        .catch(e => { /* */ });
      if (textContent) {
        if ((window as any)[SGWT_DATETIME_PICKER_NAMESPACE] === undefined) {
          (window as any)[SGWT_DATETIME_PICKER_NAMESPACE] = {};
        }
        do {
          this.uid = guid();
        } while ((window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid] !== undefined);
        window.addEventListener('error', this._globalErrorHandle);
        const script: HTMLScriptElement = document.createElement('script');
        script.id = this.uid;
        script.textContent = `window.${SGWT_DATETIME_PICKER_NAMESPACE}['${this.uid}'] = ${textContent}`;
        document.head.appendChild(script);
        window.removeEventListener('error', this._globalErrorHandle);
        (window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid].__id = this.uid;
        if ((window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid].init !== undefined) {
          (window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid].init(this._initCallback);
        }
      }
    }
  };

  /** ReactDatetime picker handler */
  private _onChange = (v: any): void => {
    if (!v || moment(v, this._getExpectedFormat(true), true).isValid()) {
      const selectedDate = moment(v, this._getExpectedFormat(true), true);
      this.isValid = true;
      const { isValidDate, isValidDateJs } = this.props;
      if (selectedDate) {
        if (isValidDateJs && this.uid && (window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid].isValidDate !== undefined) {
          const isValidDt = executeFunctionByName('isValidDate', (window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid]).bind((window as any)[SGWT_DATETIME_PICKER_NAMESPACE][this.uid]);
          this.isValid = isValidDt(selectedDate);
        } else if (isValidDate) {
          const isValidDt = executeFunctionByName(isValidDate, window);
          this.isValid = isValidDt(selectedDate);
        }
      }
      this.attributeChangedCallback('value', this.value, v);
    } else {
      this.isValid = false;
    }
  };
}

initializeWidgetConfiguration(SgwtDatetimePicker, {
  disablejQuerySanityCheck: true,
  neverUseShadowDOM: true
});

if (customElements.get(SgwtDatetimePicker.is) === undefined) {
  try {
    customElements.define(SgwtDatetimePicker.is, SgwtDatetimePicker);
  } catch (e) {
    /* */
  }
}
